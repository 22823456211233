import React from 'react';
import { Text, TextVariant } from '@naf/text';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';
import { breakpoints, spacing, themeLight } from '@naf/theme';
import { Button } from '@naf/button';
import { BodyCtaAndBodyBannerBlockType } from '../../../../../../../types/BodyCtaAndBodyBannerBlockType';
import BlockContent from '../../../../block-content/BlockContent';
import { useDocumentUrlWithNode } from '../../../../../hooks/useDocumentUrl';
import { ColorVariant } from '../../../../../../../types/featuredBenefitBlockType';

export const BodyCtaAndBodyBannerBlock = ({ block }: { block: BodyCtaAndBodyBannerBlockType }) => {
  const { title, colorVariant, body, ctaInternalLink, ctaExternalLink, rightContent } = block;

  const history = useHistory();
  const ctaUrl = useDocumentUrlWithNode(ctaInternalLink?.referenceComponent);

  return (
    <TextBulletListAndCtaBannerWrapper $variant={colorVariant}>
      {(title || body) && (
        <LeftContentWrapper>
          {title && <Text variant={TextVariant.SubHeader}>{title}</Text>}
          {body && <BlockContent value={body} />}
        </LeftContentWrapper>
      )}
      {ctaInternalLink && (
        <ButtonWrapper>
          <Button
            variant="signature"
            onClick={() => {
              if (ctaUrl) {
                history.push(ctaUrl);
              }
            }}
          >
            {ctaInternalLink.text}
          </Button>
        </ButtonWrapper>
      )}
      {ctaExternalLink && (
        <ButtonWrapper>
          <Button variant="signature" href={ctaExternalLink.link} target={ctaExternalLink.blank ? '_blank' : '_self'}>
            {ctaExternalLink.text}
          </Button>
        </ButtonWrapper>
      )}
      {(rightContent.title || rightContent.body) && (
        <RightContentWrapper>
          {rightContent.title && <Text variant={TextVariant.SubHeader}>{rightContent.title}</Text>}
          {rightContent.body && <BlockContent value={rightContent.body} />}
        </RightContentWrapper>
      )}
    </TextBulletListAndCtaBannerWrapper>
  );
};

const LeftContentWrapper = styled.div`
  grid-area: leftContent;

  *:last-child {
    margin-bottom: 0;
  }
  *:first-child {
    margin-top: 0;
  }
`;

const ButtonWrapper = styled.div`
  grid-area: button;
`;

const RightContentWrapper = styled.div`
  grid-area: rightContent;

  *:last-child {
    margin-bottom: 0;
  }
  *:first-child {
    margin-top: 0;
  }
`;

function getBackground(variant: ColorVariant) {
  return css`
    ${variant === 'signature' &&
    css`
      background: ${({ theme }) => (theme.background ? theme.background.important : themeLight.background.important)};
    `}
    ${variant === 'secondary' &&
    css`
      background: ${({ theme }) => (theme.background ? theme.background.brand : themeLight.background.brand)};
    `}
    ${variant === 'outline' &&
    css`
      background: ${({ theme }) => (theme.background ? theme.background.default : themeLight.background.default)};
    `}
    ${variant === 'spruce' &&
    css`
      background: ${({ theme }) =>
        theme.background ? theme.background.brandInverted : themeLight.background.brandInverted};
    `}
  `;
}

export const TextBulletListAndCtaBannerWrapper = styled.div<{ $variant?: ColorVariant }>`
  ${(props) =>
    props.$variant
      ? getBackground(props.$variant)
      : css`
          background: ${({ theme }) => (theme.background ? theme.background.default : themeLight.background.default)};
        `}
  padding: ${spacing.space48};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  column-gap: ${spacing.space48};
  grid-template-areas:
    'leftContent rightContent'
    'button rightContent';

  @media (max-width: ${breakpoints.m}) {
    gap: ${spacing.space24} 0;
    padding: ${spacing.space24};
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      'leftContent'
      'rightContent'
      'button';
  }
`;
